// DLRG DarkMode
$dlrgDarkMode-light: #fbfbfb;
$dlrgDarkMode-dark: #222222;
$dlrgDarkMode-gray-4: #444444;
$dlrgDarkMode-gray-5: #555555;
$dlrgDarkMode-gray-6: #666666;
$dlrgDarkMode-gray-9: #999999;


// BS-Gray (Reihenfolge invertiert)
$gray-100: #212529;
$gray-200: #343a40;
$gray-300: #495057;
$gray-400: #6c757d;
$gray-500: #adb5bd;
$gray-600: #ced4da;
$gray-700: #dee2e6;
$gray-800: #e9ecef;
//$gray-900: #f8f9fa; nicht ändern, da für .btn benötigt in dark



// Color (allgemein)
$mainColorWhite: #333333;
$mainColorBlack: #ffffff;
$mainColorLightGrey: $dlrgDarkMode-gray-6;

$headerColorGrey: $dlrgDarkMode-gray-9;
$headerColorLightGrey: $dlrgDarkMode-gray-4;

$borderColorGrey: $gray-400;

$linkColor: #70bfff;
$linkColorHover: #70e2ff;

$dlrgColorThead: #005695;

$colorFilePDF: #db6363;
$colorFileText: #6b97c7;
$colorFileSpreadsheet: #64b185;
$colorFilePresentation: #d56e3c;
$colorRSS: #FFA500;
$colorYouTube: #ff3c3c;
$colorVimeo: #44BBFF;
$colorTYPO3: #ff8700;
$colorPHP: #8A93BE;

// App Colors
$dlrgAppMeineServices: #df679c;
$dlrgAppAllgemein: #689ee0;
$dlrgAppAusbildung: #F5A623;
$dlrgAppEinsatz: #ff5861;
$dlrgAppInternet: #77d535;
$dlrgAppStammdaten: #bd8b68;
$dlrgAppLinks: #5da3df;
$dlrgAppPruefungen: #f58223;
$dlrgAppTools: #c47fff;
$dlrgAppAKI: #00a4e5;
$dlrgAppVerwaltung: #ff87d4;
$dlrgGreyTransparent: #333333bb;

// Parsley
$parsleyErrorColor: #B94A48;
$parsleyErrorBg: #F2DEDE;
$parsleyErrorBorder: #EED3D7;
$parsleySuccessColor: #468847;
$parsleySuccessBg: #DFF0D8;
$parsleySuccessBorder: #D6E9C6;
$parsleyErrorList: #E88A89;

// Code
$dlrgColorCode: #f281b5;


// extra Color für DarkModus
$body-color: $dlrgDarkMode-light;
$background: $dlrgDarkMode-gray-5 !important;
$light: $dlrgDarkMode-dark;

// BS-Cards
$card-bg: $dlrgDarkMode-gray-5;
$card-border-color: $gray-400;
$modal-header-border-color: $gray-400;
$list-group-bg: $dlrgDarkMode-gray-5;
$nav-tabs-link-active-bg: $dlrgDarkMode-gray-5;
$nav-tabs-link-active-border-color: $gray-400 $gray-400 $dlrgDarkMode-gray-5;
$nav-tabs-link-hover-border-color: $gray-400 $gray-400 $dlrgDarkMode-gray-6;

// Table
$table-accent-bg: rgba(white, 0.1); // jede 2. Zeile
$table-hover-bg: rgba(white, 0.25);

// DropDown
$dropdown-color: $dlrgDarkMode-light;
$dropdown-link-color: $dlrgDarkMode-light;
$dropdown-link-hover-color: $dlrgDarkMode-light;
$dropdown-bg: $dlrgDarkMode-gray-5;
$dropdown-border-color: $gray-400;
$card-cap-bg: $dlrgDarkMode-gray-4;

// Modal
$modal-content-bg: $dlrgDarkMode-gray-5;
$modal-content-border-color: $gray-400;
$modal-footer-border-color: $gray-400;
$close-color: $dlrgDarkMode-light;

// BS-Inputs
$input-bg: $dlrgDarkMode-gray-6;
$input-group-addon-color: $dlrgDarkMode-light;
$input-color: $dlrgDarkMode-light;
$input-placeholder-color: $gray-600;
$input-border-color: $gray-400;
$input-color-placeholder: $gray-100 !important; // weil sonst nicht wirksam

// Pagination
$pagination-color: $dlrgDarkMode-light;
$pagination-hover-color: $dlrgDarkMode-light;
$pagination-bg: $dlrgDarkMode-gray-5;
$pagination-disabled-bg: $dlrgDarkMode-dark;
$pagination-border-color: $gray-400;

// BS-Popover
$popover-bg: $dlrgDarkMode-gray-5;
$popover-border-color: $borderColorGrey;