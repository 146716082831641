/*
// Menü: anderes Adler-SVG
.sidebar {
  .nav-inner {
    background-image: url('/global/layout/isc/img/adler_dark.svg');
  }
}
*/

// AlertBoxen abdunkeln
.alert {
  filter: brightness(80%)
}

// btn-outline mehr Kontrast
*[class*="btn-outline"] {
  background: $dlrgDarkMode-dark;
}

// <hr>
hr {
  border-color: $borderColorGrey;
}

// DropDown Select
form {
  .form-group {
    .bootstrap-select {
      > .dropdown-toggle.bs-placeholder {
        &,
        &:hover,
        &:focus,
        &:active {
          color: inherit !important; //weil bootstrap-select.scss schon important
        }
      }
    }
  }
}

// BS-Table Background
.dlrg-table {
  &,
  .table-hover {
    .table-primary,
    .table-secondary,
    .table-success,
    .table-danger,
    .table-warning,
    .table-info,
    .table-light,
    .table-dark {
      &,
      &:hover {
        color: $dlrgDarkMode-dark;
      }
    }
  }
}

// Parsley-Error
.parsley-errors-list,
.form-control.parsley-error::placeholder,
.form-control.parsley-error + .dropdown-toggle::placeholder {
  color: $parsleyErrorList;
}

// acResults
#contentseite,
#startseite {
  .acResults {
    background: $dlrgDarkMode-gray-4;
  }
}

// Progress-Bar
.progress {
  background-color: $dlrgDarkMode-dark;
}

.btn-toolbar {
  .progress {
    background-color: $dlrgDarkMode-gray-5;
  }
}

// Suchtreffer BS-Table
.bootstrap-table {
  tbody {
    tr {
      td {
        span[style*="#FFFF00"] {
          color: $dlrgDarkMode-dark;
        }
      }
    }
  }
}

// FullCalendar Belegungsübersicht
// Progress-Bar
.fc .fc-list-sticky .fc-list-day > * {
  &,
  .table-active,
  .table-active > th,
  .table-active > td {
    background: $dlrgDarkMode-gray-6;
  }
}

.fc .fc-list-event:hover td {
  background-color: $dlrgDarkMode-gray-4;
}

// code
code {
  color: $dlrgColorCode;
}

// php-Fehlermeldungen
.xdebug-error,
.xe-warning {
  color: $dlrgDarkMode-dark !important; // Absolute Absicht
}

pre[class*="xdebug"] {
  color: $dlrgDarkMode-light !important; // Absolute Absicht
}

// Asset-App - Schlagwort-Feld
.token-input-dropdown-facebook {
  background-color: $dlrgDarkMode-gray-5 !important;

  p {
    color: $dlrgDarkMode-light !important;
  }

  ul {
    li {
      &,
      &.token-input-dropdown-item-facebook,
      &.token-input-dropdown-item2-facebook {
        &:not(.token-input-selected-dropdown-item-facebook) {
          background-color: $dlrgDarkMode-gray-5 !important;
        }
      }
    }
  }
}