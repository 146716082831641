.cke_chrome.cke_ltr {
  border-color: $borderColorGrey;

  .cke_inner {
    .cke_top,
    .cke_bottom {
      background: $dlrgDarkMode-gray-4;
      border-color: $gray-400;
    }

    .cke_button {
      .cke_button_icon {
        filter: invert(1);
      }

      .cke_button_label {
        color: $gray-500;
      }

      &:hover,
      &.cke_button_on {
        background: $dlrgDarkMode-dark;
        border-color: $gray-400;

        &.cke_button_disabled {
          background: unset;
          border: unset;
        }
      }
    }

    .cke_combo {
      &:hover,
      &.cke_combo_on {
        &:not(.cke_combo_disabled)::after {
          border-color: $gray-400;
        }
      }

      .cke_combo_text {
        color: $dlrgDarkMode-light;
      }

      .cke_combo_button {
        .cke_combo_text {
          margin-top: unset;
        }

        .cke_combo_arrow {
          border-color: $dlrgDarkMode-light;
          border-left: 3px solid transparent !important;
          border-right: 3px solid transparent !important;
        }
      }

      &:not(.cke_combo_disabled) {
        .cke_combo_button:hover {
          border: 1px solid $gray-400;
          background: $dlrgDarkMode-dark;

          .cke_combo_text {
            background: $dlrgDarkMode-dark;
            border-bottom: 1px solid $gray-400;
          }
        }
      }
    }

    .cke_combo_on {
      .cke_combo_button {
        &:not(.cke_combo_disabled) {
          background: $dlrgDarkMode-dark;
          border-color: $gray-400;
        }
      }
    }

    .cke_contents {
      &,
      & .cke_reset {
        background: $input-bg;
        color: $dlrgDarkMode-light;
      }
    }

    .cke_path {
      .cke_path_item {
        color: $dlrgDarkMode-light;
      }

      &:hover {
        .cke_path_item {
          color: $gray-500;
          background: unset;
        }
      }
    }
  }
}

#contentseite,
#startseite {
  .cke_ltr.cke_panel {
    border-color: $dlrgDarkMode-dark;
  }

  .cke_dialog_body,
  .cke_dialog_title {
    background: $dlrgDarkMode-gray-4;
    color: $dlrgDarkMode-light;
  }

  .cke_dialog_close_button {
    filter: invert(1);
  }

  .cke_dialog_title {
    border-bottom-color: $modal-header-border-color;
  }

  .cke_dialog_tabs {
    margin-top: 10px;
  }

  .cke_dialog_tab {
    background: none;
    border: none;
    color: $linkColor;

    &:hover {
      border: $gray-400;
      color: $linkColorHover;
    }

    &.cke_dialog_tab_selected {
      background: $dlrgDarkMode-gray-5;
      border: $gray-400 1px solid;
      border-bottom-color: $dlrgDarkMode-gray-5;
      color: $dlrgDarkMode-light;
    }
  }

  .cke_dialog_contents,
  .cke_about_container,
  .cke_about_logo,
  .cke_dialog_footer {
    background-color: $dlrgDarkMode-gray-5;
    border-top: none;

    &,
    p {
      color: $dlrgDarkMode-light !important;
    }

    input,
    select {
      background: $input-bg;
      color: $input-color;
    }

    label {
      color: $dlrgDarkMode-light;
    }

    .cke_dark_background {
      color: $dlrgDarkMode-light;
    }

    .cke_smile:active,
    .cke_specialchar {
      background: none;
      border: none;
      padding: 5px;

      &,
      * {
        color: $dlrgDarkMode-light;
      }

      &:focus,
      &:hover,
      &:active {
        background: $dlrgDarkMode-dark;
        border: none;
      }
    }
  }
}