.highcharts-container {
  path,
  rect,
  .highcharts-contextmenu {
    filter: invert(77%);
  }

  text,
  span,
  p {
    color: $dlrgDarkMode-light !important; //dass inline CSS überschrieben wird
    fill: $dlrgDarkMode-light !important; //dass inline CSS überschrieben wird
  }

  g {
    text {
      color: $black !important; //dass inline CSS überschrieben wird
      fill: $black !important; //dass inline CSS überschrieben wird
      filter: invert(85%);
    }
  }

  .highcharts-menu-item {
    color: $white !important; //dass inline CSS überschrieben wird
    filter: invert(100%); // dass Filter zurückgesetzt wird nochmal invertieren
  }
}